<template>
  <div class="auth-main viewContainer" >
    <v-container class="auth-container-accounting pa-4">
      <v-btn 
          :to="{ name: 'billing-history' }"
            small
            rounded
            depressed 
            class="no-caps mb-4 "
        >
          <v-icon left>mdi-arrow-left</v-icon>
          {{ $t('back') }}
        </v-btn>
      <v-card rounded class="pt-5">
        <v-tabs 
          v-model="tab_payment"
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs pl-5 pr-5 mb-3"
          background-color="transparent"
          fixed-tabs
          show-arrows
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-if="$auth.user().payment_transfer" @click="selectPayment('transfer')" class="no-caps" :href="'#transfer'" :ripple="false">
            {{ $t('transfer') }}
          </v-tab>
          <v-tab v-if="$auth.user().payment_credit_card" @click="selectPayment('credit_card')" class="no-caps" :href="'#credit_card'" :ripple="false">
            {{ $t('credit_card') }}
          </v-tab>
          <v-tab v-if="$auth.user().payment_promptpay" @click="selectPayment('promptpay')" class="no-caps" :href="'#promptpay'" :ripple="false">
            {{ $t('promptpay') }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab_payment" :touchless="false">
          <v-tab-item v-if="$auth.user().payment_transfer" :transition="false" :reverse-transition="false" :value="'transfer'">
            <payment_transfer ref="payment_transfer"></payment_transfer>
          </v-tab-item>
          <v-tab-item v-if="$auth.user().payment_credit_card" :transition="false" :reverse-transition="false" :value="'credit_card'">
            <payment_credit_card ref="payment_credit_card"></payment_credit_card>
          </v-tab-item>
          <v-tab-item v-if="$auth.user().payment_promptpay" :transition="false" :reverse-transition="false" :value="'promptpay'">
            <payment_promptpay ref="payment_promptpay"></payment_promptpay>
          </v-tab-item>
        </v-tabs-items>
        <!-- <v-tabs 
          v-model="tab_payment"
          :color="($store.state.app.dark) ? 'white' : 'black'"
          show-arrows
        >
          <v-tab 
          @click="selectPayment('transfer')"
          :href="'#transfer'" 
          class="icon_payment" 
          >
            <v-icon center>
              mdi-cash-multiple
            </v-icon>
            <div>{{ $t('transfer') }}</div>
          </v-tab>

          <v-tab
          @click="selectPayment('credit_card')"
          :href="'#credit_card'" 
          class="icon_payment" 
          >
            <v-icon center>
              mdi-credit-card
            </v-icon>
            <div>{{ $t('credit_card') }}</div>
          </v-tab>
          <v-tab
          @click="selectPayment('promptpay')"
          :href="'#promptpay'" 
          class="icon_payment" 
          >
            <v-icon center>
              mdi-qrcode
            </v-icon>
            <div>{{ $t('promptpay') }}</div>
          </v-tab>

          <v-tab-item :transition="false" :reverse-transition="false" :value="'transfer'">
            <payment_transfer ref="payment_transfer"></payment_transfer>
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false" :value="'credit_card'">
            <payment_credit_card ref="payment_credit_card"></payment_credit_card>
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false" :value="'promptpay'">
            <payment_promptpay ref="payment_promptpay"></payment_promptpay>
          </v-tab-item>
        </v-tabs> -->
      </v-card>
    </v-container>
  </div>
</template>
<script>
  export default {

    data: () => ({
      tab_payment: 'transfer',
      bank_account: [],
      main_currency: 'USD',
      main_currency_rate: 1,
      rate_thb: 1,
      item_currency: [],
      fee_transfer: 0,
      fee_credit_card: 0,
      fee_promptpay: 0,
      service_fee_transfer: 0,
      service_fee_credit_card: 0,
      service_fee_promptpay: 0,
      omise_customer: {},
      status_payment_transfer: false,
      status_payment_credit_card: false,
      status_payment_promptpay: false,
      default_amount: 0
    }),
    created () {
      // Set locale
      //console.log(this.country)
      //console.log(this.$store.state.app.data_payment.tap)

      if(this.$auth.user().status_enterprise || !this.$auth.user().page_payment){
        this.$router.push({
          name: 'settings.profile',
        })
      }

      if(this.$store.state && this.$store.state && this.$store.state.app.data_payment && this.$store.state.app.data_payment.tap){
        this.tab_payment = this.$store.state.app.data_payment.tap
        this.selectPayment(this.tab_payment)
        //this.$refs.payment_transfer.end_loading()
      }
      else{
        if(this.$route.params && this.$route.params.credit){
          this.default_amount = this.$route.params.credit
        }
        this.tab_payment = 'transfer'
        this.selectPayment(this.tab_payment)
      }
      
    },
    methods: {
      selectPayment(type){
        let data_payment = this.$store.state.app.data_payment
        data_payment['tap'] = type
        this.$store.dispatch('setDataPayment', data_payment)
        let that = this
        
          this.$nextTick(() => {
            if(type == 'transfer'){
              if (this.$refs.payment_transfer) {
                this.$refs.payment_transfer.open(
                  false,
                  this.default_amount,
                )
              }
              else{
                setTimeout(function(){
                  that.selectPayment('transfer')
                },0)
              }
            }
            else if(type == 'credit_card'){
              if (this.$refs.payment_credit_card) {
                this.$refs.payment_credit_card.open()
              }
              else{
                setTimeout(function(){
                  that.selectPayment('credit_card')
                },0)
              }
            }
            else if(type == 'promptpay'){
              if (this.$refs.payment_promptpay) {
                this.$refs.payment_promptpay.open()
              }
              else{
                setTimeout(function(){
                  that.selectPayment('promptpay')
                },0)
              }
            }
            
          });
      }
    }
  }
</script>
<style>
.custom-text-field{
  margin: 0 !important;
  width: 100%;
  max-width: 100% !important;
}
</style>