<template>
  <div class="auth-main viewContainer" >
    <v-container class="auth-container pa-4">
      <v-btn 
        :to="{ 
            name: 'lighthouse-history',
            query: $route.query
          }"
          small
          rounded
          depressed 
          class="no-caps mb-4"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        {{ $t('back') }}
      </v-btn>
      <!-- <v-btn @click="loading_main ? loading_main=false : loading_main=true">test</v-btn> -->
      <ValidationObserver ref="validateLighthouse" v-slot="{ invalid }">
        <v-form 
          ref="formLighthouse" 
          @submit.prevent="SaveLighthouse"
        >
          <v-card rounded>
            <v-card-title class="pt-5 pl-5 pr-5 pb-2 ma-0" >{{ $t('create_lighthouse') }}</v-card-title>
            <v-card-text class="pa-5 ma-0">

              <v-tabs 
                v-model="formLighthouse.tab"
                :color="($store.state.app.dark) ? 'white' : 'black'"
                class="content-tabs mb-3"
                background-color="transparent"
                fixed-tabs
                show-arrows
                @change="setDataLighthouse"
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab class="no-caps" :href="'#domain'" :ripple="false">
                  {{ $t('domain') }}
                </v-tab>
                <v-tab class="no-caps" :href="'#url'" :ripple="false">
                  {{ $t('url') }}
                </v-tab>
              </v-tabs>

              <x-text-field
                v-if="formLighthouse.tab == 'domain'"
                v-model="formLighthouse.site"
                rules="required"
                :label="$t('domain')"
                filled
                :error="formLighthouse.error_website"
                @keyup="setDataLighthouse"
                placeholder="https://website.com"
              ></x-text-field>

              <!-- <x-text-field
                v-if="formLighthouse.tab == 'url'"
                v-model="formLighthouse.site"
                rules="required"
                :label="$t('url')"
                filled
                :error="formLighthouse.error_website"
                @keyup="setDataLighthouse"
                placeholder="https://website.com/url"
              ></x-text-field> -->

              <x-text-area
                v-if="formLighthouse.tab == 'url'"
                v-model="formLighthouse.urls"
                @keyup="setDataLighthouse"
                :label="$t('url')"
                rules="required"
                filled
                rounded
                persistent-placeholder
                persistent-hint
                label-placement="on-top"
                class="custom-text-field mt-4 text-area-wrap"
                :error="formLighthouse.error_urls"
                :placeholder="'https://website.com/url1\nhttps://website.com/url2'"
              ></x-text-area>

              <div class="v-label mb-3">{{ $t('device') }}</div>
              <v-tabs 
                v-model="formLighthouse.device"
                :color="($store.state.app.dark) ? 'white' : 'black'"
                class="content-tabs mb-3"
                background-color="transparent"
                fixed-tabs
                show-arrows
                @change="setDataLighthouse"
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab class="no-caps" :href="'#'+device.value" :ripple="false" v-for="(device, devices_index) in formLighthouse.devices" :key="devices_index">
                  <span v-if="$vuetify.breakpoint.width > 600" class="">{{ device.name }}</span>
                  <v-icon right >
                    {{ device.icon }}
                  </v-icon>   
                </v-tab>
              </v-tabs>


              <v-switch
                  v-model="formLighthouse.status_advance"
                  class="mt-0"
                  ref="status_advance"
                  id="status_advance"
                  :label="$t('advanced_options')"
                  inset
                  dense
                  :ripple="false"
                  name="status_advance"
                  :persistent-hint="true"
                  @change="setDataLighthouse"
              />

              <div v-if="formLighthouse.status_advance">

                <x-text-area
                  v-if="formLighthouse.tab == 'domain'"
                  v-model="formLighthouse.sitemaps"
                  @keyup="setDataLighthouse"
                  :label="$t('sitemaps')"
                  filled
                  rounded
                  persistent-placeholder
                  persistent-hint
                  label-placement="on-top"
                  class="custom-text-field mt-4 text-area-wrap"
                  :error="formLighthouse.error_sitemaps"
                  :placeholder="'https://website.com/url1\nhttps://website.com/url2'"
                ></x-text-area>

                <x-text-area
                  v-if="formLighthouse.tab == 'domain'"
                  v-model="formLighthouse.include_urls"
                  @keyup="setDataLighthouse"
                  :label="$t('include_urls')"
                  filled
                  rounded
                  persistent-placeholder
                  persistent-hint
                  label-placement="on-top"
                  class="custom-text-field mt-4 text-area-wrap"
                  :error="formLighthouse.error_include_urls"
                  :placeholder="'https://website.com/url1\nhttps://website.com/url2'"
                ></x-text-area>

                <x-text-area
                  v-if="formLighthouse.tab == 'domain'"
                  v-model="formLighthouse.exclude_urls"
                  @keyup="setDataLighthouse"
                  :label="$t('exclude_urls')"
                  filled
                  rounded
                  persistent-placeholder
                  persistent-hint
                  label-placement="on-top"
                  class="custom-text-field mt-4 text-area-wrap"
                  :error="formLighthouse.error_exclude_urls"
                  :placeholder="'https://website.com/url1\nhttps://website.com/url2'"
                ></x-text-area>

                <!-- <x-select
                  v-model="formLighthouse.webhook_mode"
                  :items="formLighthouse.item_webhook_mode"
                  item-text="name"
                  item-value="value"
                  :label="$t('webhook_mode')"
                  @change="setDataLighthouse"
                  :rules="'required'"
                  filled
                ></x-select> -->
                
                <x-text-field
                  v-model="formLighthouse.samples"
                  :label="$t('samples')"
                  :rules="'integer|between:1,10'"
                  type="number"
                  filled
                  @keyup="setDataLighthouse"
                ></x-text-field>

                <x-text-field
                  v-model="formLighthouse.budget"
                  :label="$t('budget')"
                  :rules="'integer|between:1,100'"
                  type="number"
                  filled
                  @keyup="setDataLighthouse"
                ></x-text-field>

                <x-text-field
                  v-model="formLighthouse.timeout"
                  :label="$t('timeout')"
                  :rules="'integer'"
                  type="number"
                  filled
                  @keyup="setDataLighthouse"
                ></x-text-field>

                <v-switch
                  v-model="formLighthouse.throttle"
                  class="mt-0"
                  ref="throttle"
                  id="throttle"
                  :label="$t('throttle')"
                  inset
                  dense
                  :ripple="false"
                  name="throttle"
                  :persistent-hint="true"
                  @change="setDataLighthouse"
                />

                <v-switch
                  v-model="formLighthouse.disable_sitemap"
                  class="mt-0"
                  ref="disable_sitemap"
                  id="disable_sitemap"
                  :label="$t('disable_sitemap')"
                  inset
                  dense
                  :ripple="false"
                  name="disable_sitemap"
                  :persistent-hint="true"
                  @change="setDataLighthouse"
                />

                <v-switch
                  v-model="formLighthouse.disable_javascript"
                  class="mt-0"
                  ref="disable_javascript"
                  id="disable_javascript"
                  :label="$t('disable_javascript')"
                  inset
                  dense
                  :ripple="false"
                  name="disable_javascript"
                  :persistent-hint="true"
                  @change="setDataLighthouse"
                />

                <v-switch
                  v-model="formLighthouse.disable_i18n_pages"
                  class="mt-0"
                  ref="disable_i18n_pages"
                  id="disable_i18n_pages"
                  :label="$t('disable_i18n_pages')"
                  inset
                  dense
                  :ripple="false"
                  name="disable_i18n_pages"
                  :persistent-hint="true"
                  @change="setDataLighthouse"
                />

                <v-switch
                  v-model="formLighthouse.disable_robots_txt"
                  class="mt-0"
                  ref="disable_robots_txt"
                  id="disable_robots_txt"
                  :label="$t('disable_robots_txt')"
                  inset
                  dense
                  :ripple="false"
                  name="disable_robots_txt"
                  :persistent-hint="true"
                  @change="setDataLighthouse"
                />

                <v-switch
                  v-model="formLighthouse.debug"
                  class="mt-0"
                  ref="debug"
                  id="debug"
                  :label="$t('debug')"
                  inset
                  dense
                  :ripple="false"
                  name="debug"
                  :persistent-hint="true"
                  @change="setDataLighthouse"
                />

                <v-switch
                  v-model="formLighthouse.disable_dynamic_sampling"
                  class="mt-0"
                  ref="disable_dynamic_sampling"
                  id="disable_dynamic_sampling"
                  :label="$t('disable_dynamic_sampling')"
                  inset
                  dense
                  :ripple="false"
                  name="disable_dynamic_sampling"
                  :persistent-hint="true"
                  @change="setDataLighthouse"
                />

                <x-text-field
                  v-model="formLighthouse.dynamic_sampling"
                  :label="$t('dynamic_sampling')"
                  :rules="'integer'"
                  type="number"
                  filled
                  @keyup="setDataLighthouse"
                ></x-text-field>

                <x-text-field
                  v-model="formLighthouse.max_routes"
                  :label="$t('max_routes')"
                  :rules="'integer'"
                  type="number"
                  filled
                  @keyup="setDataLighthouse"
                ></x-text-field>

                <x-text-field
                  v-model="formLighthouse.concurrency"
                  :label="$t('concurrency')"
                  :rules="'integer|between:1,10'"
                  type="number"
                  filled
                  @keyup="setDataLighthouse"
                ></x-text-field>
                
                <x-text-field
                  v-model="formLighthouse.ttl"
                  :label="$t('ttl')"
                  :rules="'integer'"
                  type="number"
                  filled
                  @keyup="setDataLighthouse"
                ></x-text-field>

                <x-text-field
                  v-model="formLighthouse.job_timeout"
                  :label="$t('job_timeout')"
                  :rules="'integer'"
                  type="number"
                  filled
                  @keyup="setDataLighthouse"
                ></x-text-field>

                <x-text-field
                  v-model="formLighthouse.job_cpu"
                  :label="$t('job_cpu')"
                  :rules="'integer|between:1,8'"
                  type="number"
                  filled
                  @keyup="setDataLighthouse"
                ></x-text-field>

                <x-text-field
                  v-model="formLighthouse.job_memory"
                  :label="$t('job_memory')"
                  :rules="'integer|between:1,32'"
                  type="number"
                  filled
                  @keyup="setDataLighthouse"
                ></x-text-field>

                <v-switch     
                    v-model="formLighthouse.status_job_region"
                    class="mt-0"
                    ref="status_job_region"
                    id="status_job_region"
                    :label="$t('job_region')"
                    inset
                    dense
                    :ripple="false"
                    name="status_job_region"
                    :persistent-hint="true"
                    @change="setDataLighthouse"
                />

                <x-select
                  v-if="formLighthouse.status_job_region"
                  v-model="formLighthouse.job_region"
                  :items="formLighthouse.item_job_region"
                  item-text="name"
                  item-value="value"
                  :label="$t('job_region')"
                  @change="setDataLighthouse"
                  :rules="'required'"
                  filled
                ></x-select>

                <v-switch     
                    v-model="formLighthouse.status_extra_headers"
                    class="mt-0"
                    ref="status_extra_headers"
                    id="status_extra_headers"
                    :label="$t('extra_headers')"
                    inset
                    dense
                    :ripple="false"
                    name="status_extra_headers"
                    :persistent-hint="true"
                    @change="setDataLighthouse"
                />

                <v-card v-if="formLighthouse.status_extra_headers" rounded outlined class="mb-3 pa-2">
                  <div class="v-label pa-2">{{ $t('extra_headers') }}</div>
                  <v-card v-for="(extra_header, extra_header_index) in formLighthouse.extra_headers" :key="extra_header_index" rounded outlined class="pt-5 pl-5 pr-5 mb-0 mt-2" >
                    <v-btn 
                      v-if="extra_header_index == 0"
                      fab 
                      small 
                      text 
                      @click.native="addObject('extra_headers')" 
                      color="primary" 
                      class="no-caps border-radius-circle btn_object"
                    >
                      <v-icon size="20">mdi-plus</v-icon>
                    </v-btn>
                    <v-btn 
                      v-if="extra_header_index > 0"
                      fab 
                      small 
                      text 
                      @click.native="removeObject('extra_headers',extra_header_index)" 
                      color="grey" 
                      class="no-caps border-radius-circle btn_object"
                    >
                      <v-icon size="20">mdi-close</v-icon>
                    </v-btn>
                    <x-text-field
                      v-model="extra_header.name"
                      :label="$t('key')"
                      :rules="'required'"
                      filled
                      @keyup="setDataLighthouse"
                    ></x-text-field>
                    <x-text-field
                      v-model="extra_header.value"
                      :label="$t('value')"
                      :rules="'required'"
                      filled
                      @keyup="setDataLighthouse"
                    ></x-text-field>
                  </v-card>
                </v-card>
              
                <v-switch     
                    v-model="formLighthouse.status_cookies"
                    class="mt-0"
                    ref="status_cookies"
                    id="status_cookies"
                    :label="$t('cookies')"
                    inset
                    dense
                    :ripple="false"
                    name="status_cookies"
                    :persistent-hint="true"
                    @change="setDataLighthouse"
                />

                <v-card v-if="formLighthouse.status_cookies" rounded outlined class="mb-3 pa-2">
                  <div class="v-label pa-2">{{ $t('cookies') }}</div>
                  <v-card v-for="(cookie, cookie_index) in formLighthouse.cookies" :key="cookie_index" rounded outlined class="pt-5 pl-5 pr-5 mb-0 mt-2" >
                    <v-btn 
                      v-if="cookie_index == 0"
                      fab 
                      small 
                      text 
                      @click.native="addObject('cookies')" 
                      color="primary" 
                      class="no-caps border-radius-circle btn_object"
                    >
                      <v-icon size="20">mdi-plus</v-icon>
                    </v-btn>
                    <v-btn 
                      v-if="cookie_index > 0"
                      fab 
                      small 
                      text 
                      @click.native="removeObject('cookies',cookie_index)" 
                      color="grey" 
                      class="no-caps border-radius-circle btn_object"
                    >
                      <v-icon size="20">mdi-close</v-icon>
                    </v-btn>
                    <x-text-field
                      v-model="cookie.name"
                      :label="$t('key')"
                      :rules="'required'"
                      filled
                      @keyup="setDataLighthouse"
                    ></x-text-field>
                    <x-text-field
                      v-model="cookie.value"
                      :label="$t('value')"
                      :rules="'required'"
                      filled
                      @keyup="setDataLighthouse"
                    ></x-text-field>
                  </v-card>
                </v-card>

                <v-switch     
                    v-model="formLighthouse.status_default_query_params"
                    class="mt-0"
                    ref="status_default_query_params"
                    id="status_default_query_params"
                    :label="$t('default_query_params')"
                    inset
                    dense
                    :ripple="false"
                    name="status_default_query_params"
                    :persistent-hint="true"
                    @change="setDataLighthouse"
                />

                <v-card v-if="formLighthouse.status_default_query_params" rounded outlined class="mb-3 pa-2">
                  <div class="v-label pa-2">{{ $t('default_query_params') }}</div>
                  <v-card v-for="(default_query_params, default_query_params_index) in formLighthouse.default_query_params" :key="default_query_params_index" rounded outlined class="pt-5 pl-5 pr-5 mb-0 mt-2" >
                    <v-btn 
                      v-if="default_query_params_index == 0"
                      fab 
                      small 
                      text 
                      @click.native="addObject('default_query_params')" 
                      color="primary" 
                      class="no-caps border-radius-circle btn_object"
                    >
                      <v-icon size="20">mdi-plus</v-icon>
                    </v-btn>
                    <v-btn 
                      v-if="default_query_params_index > 0"
                      fab 
                      small 
                      text 
                      @click.native="removeObject('default_query_params',default_query_params_index)" 
                      color="grey" 
                      class="no-caps border-radius-circle btn_object"
                    >
                      <v-icon size="20">mdi-close</v-icon>
                    </v-btn>
                    <x-text-field
                      v-model="default_query_params.name"
                      :label="$t('key')"
                      :rules="'required'"
                      filled
                      @keyup="setDataLighthouse"
                    ></x-text-field>
                    <x-text-field
                      v-model="default_query_params.value"
                      :label="$t('value')"
                      :rules="'required'"
                      filled
                      @keyup="setDataLighthouse"
                    ></x-text-field>
                  </v-card>
                </v-card>
                  
                <v-switch
                    v-model="formLighthouse.status_auth"
                    class="mt-0"
                    ref="status_auth"
                    id="status_auth"
                    :label="$t('auth')"
                    inset
                    dense
                    :ripple="false"
                    name="status_auth"
                    :persistent-hint="true"
                    @change="setDataLighthouse"
                />

                <x-text-field
                  v-if="formLighthouse.status_auth"
                  v-model="formLighthouse.username"
                  :label="$t('username')"
                  :rules="'required'"
                  filled
                  @keyup="setDataLighthouse"
                ></x-text-field>
                <x-text-field
                  v-if="formLighthouse.status_auth"
                  v-model="formLighthouse.password"
                  :label="$t('password')"
                  :rules="'required'"
                  filled
                  @keyup="setDataLighthouse"
                ></x-text-field>
              </div>

              
              
              
              <!-- <div v-if="!$auth.user().status_enterprise">
                <div class="view_div d-flex justify-space-between" >
                  <div class="text-body-1 mt-auto mb-auto">{{$t('price')}}</div>
                  <div class="text-h6">
                    <amount_currency :amount="parseFloat(formLighthouse.price_lighthouse[formLighthouse.tab])" top></amount_currency>
                  </div>
                </div>

                <div class="view_div d-flex justify-space-between" v-if="formLighthouse.tab == 'url'">
                  <div class="text-body-1 mt-auto mb-auto">{{$t('quantity')}} {{$t('url')}}</div>
                  <div class="text-h6">{{ formLighthouse.count_urls ? parseInt(formLighthouse.count_urls) : 0 }}</div>
                </div>

                <div class="view_div d-flex justify-space-between" v-if="formLighthouse.tab == 'url'">
                  <div class="text-body-1 mt-auto mb-auto">{{$t('amount')}} ({{ $t('when_active') }})</div>
                  <div class="text-h6 mt-auto mb-auto">
                    <amount_currency :amount="parseFloat(formLighthouse.total_price)" top></amount_currency>
                  </div>
                </div>
              </div> -->
              

            </v-card-text>
            <v-card-actions class="pl-5 pr-5 pb-5 pt-0 ma-0 ">
              <v-spacer></v-spacer>
              <v-btn 
                type="submit" 
                color="primary" 
                depressed 
                class="no-caps"
                :loading="loading" :disabled="loading || invalid"
                >{{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-container>
  </div>
</template>
<script>


  export default {
    data: () => ({
      resolve: null,
      reject: null,
      loading: false,
      formLighthouse: {
        tab: 'domain',
        price: 0,
        total_price: 0,
        extra_headers: [],
        cookies: [],
        default_query_params: [],
        price_lighthouse: {},
        item_webhook_mode: [],
        item_job_region: [],
        error_website: false,
        error_urls: false,
        error_sitemaps: false,
        error_include_urls: false,
        error_exclude_urls: false,
      },
      
    }),
    created () {
      // Set locale
      if(!this.$auth.user().page_lighthouse){
        this.$router.push({
          name: 'settings.profile',
        })
      }
      this.open()
    },
    methods: {
      handleSitemaps(event) {
        const TAB = '\t';  // เว้นวรรค 4 ช่อง คุณสามารถปรับเป็นตามที่ต้องการ
        const start = event.target.selectionStart;
        const end = event.target.selectionEnd;

        // ใส่ TAB ลงในตำแหน่งที่เลือกและอัปเดตตำแหน่ง cursor
        this.formLighthouse.sitemaps = this.formLighthouse.sitemaps.substring(0, start) + TAB + this.formLighthouse.keyword_multiple.substring(end);
        this.$nextTick(() => {
          event.target.selectionStart = event.target.selectionEnd = start + TAB.length;
        });
      },
      open() {
        if(this.$store.state && this.$store.state && this.$store.state.app.data_lighthouse && this.$store.state.app.data_lighthouse.formLighthouse){
          this.formLighthouse = this.$store.state.app.data_lighthouse.formLighthouse
          this.loading = false
        }
        else{
          this.setData() 
          // this.loading = true
          // this.axios.get('/lighthouse/get-price')
          // .then(res => {
          //   if (res.data.status === 'success') {
          //     //console.log(res.data)
          //     this.loading = false
          //     this.formLighthouse.price_lighthouse = res.data.price_lighthouse
          //     // this.formLighthouse.item_webhook_mode = [
          //     //   {
          //     //     value: 'every',
          //     //     name: this.$t('every')
          //     //   },
          //     //   {
          //     //     value: 'final',
          //     //     name: this.$t('final')
          //     //   }
          //     // ]
          //     // this.formLighthouse.webhook_mode = 'every'
          //     this.setData() 
          //   }
          // })
          // .catch(err => {
          //   this.loading = false
          //   if (err.response.data.status === 'error') {
          //     this.$root.$snackbar(err.response.data.msg)
          //   }
          // })

        }
      },
      setDataLighthouse(){
        let data_lighthouse = this.$store.state.app.data_lighthouse
        data_lighthouse['formLighthouse'] = this.formLighthouse
        this.$store.dispatch('setDataLighthouse', data_lighthouse)
      },
      setData() {
        this.formLighthouse.tab = 'domain'
        if(this.$refs.validateLighthouse){
          this.$refs.validateLighthouse.reset();
        }
        this.formLighthouse.site = null
        this.formLighthouse.devices = [
          {
            value: 'desktop',
            icon: 'mdi-desktop-mac',
            name: this.$t('desktop')
          },
          {
            value: 'mobile',
            icon: 'mdi-cellphone-android',
            name: this.$t('mobile')
          }
        ]
        this.formLighthouse.extra_headers = [
          {
            value: '',
            name: '',
          },
        ]
        this.formLighthouse.cookies = [
          {
            value: '',
            name: '',
          },
        ]
        this.formLighthouse.default_query_params = [
          {
            value: '',
            name: '',
          },
        ]

        this.formLighthouse.item_job_region = [
          {
            value: 'asia-east1',
            name: 'asia-east1'
          },
          {
            value: 'asia-east2',
            name: 'asia-east2'
          },
          {
            value: 'asia-northeast1',
            name: 'asia-northeast1'
          },
          {
            value: 'asia-northeast2',
            name: 'asia-northeast2'
          },
          {
            value: 'asia-northeast3',
            name: 'asia-northeast3'
          },
          {
            value: 'asia-southeast1',
            name: 'asia-southeast1'
          },
          {
            value: 'asia-southeast2',
            name: 'asia-southeast2'
          },
          {
            value: 'asia-south1',
            name: 'asia-south1'
          },
          {
            value: 'asia-south2',
            name: 'asia-south2'
          },
          {
            value: 'australia-southeast1',
            name: 'australia-southeast1'
          },
          {
            value: 'australia-southeast2',
            name: 'australia-southeast2'
          },
          {
            value: 'europe-north1',
            name: 'europe-north1'
          },
          {
            value: 'europe-central2',
            name: 'europe-central2'
          },
          {
            value: 'europe-southwest1',
            name: 'europe-southwest1'
          },
          {
            value: 'europe-west1',
            name: 'europe-west1'
          },
          {
            value: 'europe-west2',
            name: 'europe-west2'
          },
          {
            value: 'europe-west3',
            name: 'europe-west3'
          },
          {
            value: 'europe-west4',
            name: 'europe-west4'
          },
          {
            value: 'europe-west6',
            name: 'europe-west6'
          },
          {
            value: 'europe-west8',
            name: 'europe-west8'
          },
          {
            value: 'europe-west9',
            name: 'europe-west9'
          },
          {
            value: 'europe-west10',
            name: 'europe-west10'
          },
          {
            value: 'europe-west12',
            name: 'europe-west12'
          },
          {
            value: 'me-west1',
            name: 'me-west1'
          },
          {
            value: 'me-central1',
            name: 'me-central1'
          },
          {
            value: 'us-central1',
            name: 'us-central1'
          },
          {
            value: 'us-east1',
            name: 'us-east1'
          },
          {
            value: 'us-east4',
            name: 'us-east4'
          },
          {
            value: 'us-east5',
            name: 'us-east5'
          },
          {
            value: 'us-south1',
            name: 'us-south1'
          },
          {
            value: 'us-west1',
            name: 'us-west1'
          },
          {
            value: 'africa-south1',
            name: 'africa-south1'
          },
        ]

        this.formLighthouse.job_region = 'asia-southeast1'

        this.setDataLighthouse()
      },
      SaveLighthouse() {
        this.formLighthouse.error_website = false
        this.formLighthouse.error_urls = false
        this.formLighthouse.error_sitemaps = false
        this.formLighthouse.error_include_urls = false
        this.formLighthouse.error_exclude_urls = false

        const pattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[^\s]{2,})$/gi;
        if(this.formLighthouse.tab == 'domain'){
          if(!pattern.test(this.formLighthouse.site)){
            this.$root.$snackbar(this.$t('invalid_website'))
            this.formLighthouse.error_website = true
            return false
          }
        }
        
        let array_urls = []
        if(this.formLighthouse.tab == 'url'){
          let array = this.formLighthouse.urls.split('\n')
          //console.log(array)
          if(!array.length){
            this.$root.$snackbar(this.$t('invalid_value'))
            this.formLighthouse.error_urls = true
            return false
          }
          for(let urls of array){
            //console.log(urls)
            //console.log(pattern.test(urls))
            if(!urls){
              continue;
            }
            const pattern_urls = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[^\s]{2,})$/gi;
            if(pattern_urls.test(urls) == false){
              this.$root.$snackbar(this.$t('invalid_website'))
              this.formLighthouse.error_urls = true
              return false
            }
            array_urls.push(urls.trim())
          }
        }
        console.log(array_urls)

        let array_sitemaps = []
        if(this.formLighthouse.tab == 'domain' && this.formLighthouse.sitemaps){
          let array = this.formLighthouse.sitemaps.split('\n')
          //console.log(array_keyword)
          if(!array.length){
            this.$root.$snackbar(this.$t('invalid_value'))
            this.formLighthouse.error_sitemaps = true
            return false
          }
          for(let sitemaps of array){
            // console.log(sitemaps)
            console.log(pattern.test(sitemaps))
            if(!sitemaps){
              continue;
            }
            if(!pattern.test(sitemaps)){
              this.$root.$snackbar(this.$t('invalid_website'))
              this.formLighthouse.error_sitemaps = true
              return false
            }
            array_sitemaps.push(sitemaps.trim())
          }
        }
        
        let array_include_urls = []
        if(this.formLighthouse.tab == 'domain' && this.formLighthouse.include_urls){
          let array = this.formLighthouse.include_urls.split('\n')
          //console.log(array_keyword)
          if(!array.length){
            this.$root.$snackbar(this.$t('invalid_value'))
            this.formLighthouse.error_include_urls = true
            return false
          }
          for(let include_urls of array){
            if(!include_urls){
              continue;
            }
            console.log(pattern.test(include_urls))
            if(!pattern.test(include_urls)){
              this.$root.$snackbar(this.$t('invalid_website'))
              this.formLighthouse.error_include_urls = true
              return false
            }
            array_include_urls.push(include_urls.trim())
          }
        }
        let array_exclude_urls = []
        if(this.formLighthouse.tab == 'domain' && this.formLighthouse.exclude_urls){
          let array = this.formLighthouse.exclude_urls.split('\n')
          //console.log(array_keyword)
          if(!array.length){
            this.$root.$snackbar(this.$t('invalid_value'))
            this.formLighthouse.error_exclude_urls = true
            return false
          }
          for(let exclude_urls of array){
            if(!exclude_urls){
              continue;
            }
            console.log(pattern.test(exclude_urls))
            if(!pattern.test(exclude_urls)){
              this.$root.$snackbar(this.$t('invalid_website'))
              this.formLighthouse.error_exclude_urls = true
              return false
            }
            array_exclude_urls.push(exclude_urls.trim())
          }
        }
        //console.log(array_sitemaps)

        this.$root.$confirm(this.$t('confirm'), this.$t('confirm_create_lighthouse'),{
          color: 'primary',
          width: 360,
          zIndex: 200
        },false)
        .then((confirm) => {
          if (confirm.confirm) {
            this.loading = true
            let data_post = {
              type: this.formLighthouse.tab,
              site: this.formLighthouse.site,
              urls: JSON.stringify(array_urls),
              device: this.formLighthouse.device,
              // sitemaps: JSON.stringify(array_sitemaps),
              // disable_sitemap: (this.formLighthouse.disable_sitemap ? 1 : 0),
              status_advance: (this.formLighthouse.status_advance ? 1 : 0),
              // samples: this.formLighthouse.samples,
              // budget: this.formLighthouse.budget,
              // timeout: this.formLighthouse.timeout,
              // webhook_mode: this.formLighthouse.webhook_mode,
              // include_urls: JSON.stringify(array_include_urls),
              // exclude_urls: JSON.stringify(array_exclude_urls),
            }
            if(this.formLighthouse.status_advance){


              if(array_sitemaps.length){
                data_post['sitemaps'] = JSON.stringify(array_sitemaps)
              }

              if(array_include_urls.length){
                data_post['include_urls'] = JSON.stringify(array_include_urls)
              }
              if(array_exclude_urls.length){
                data_post['exclude_urls'] = JSON.stringify(array_exclude_urls)
              }
              
              if(this.formLighthouse.samples){
                data_post['samples'] = this.formLighthouse.samples
              }

              if(this.formLighthouse.samples){
                data_post['samples'] = this.formLighthouse.samples
              }

              if(this.formLighthouse.budget){
                data_post['budget'] = this.formLighthouse.budget
              }

              if(this.formLighthouse.timeout){
                data_post['timeout'] = this.formLighthouse.timeout
              }

              if(this.formLighthouse.throttle){
                data_post['throttle'] = 1
              }

              if(this.formLighthouse.disable_javascript){
                data_post['disable_javascript'] = 1
              }
              
              if(this.formLighthouse.disable_i18n_pages){
                data_post['disable_i18n_pages'] = 1
              }

              if(this.formLighthouse.disable_robots_txt){
                data_post['disable_robots_txt'] = 1
              }

              if(this.formLighthouse.debug){
                data_post['debug'] = 1
              }

              if(this.formLighthouse.disable_sitemap){
                data_post['disable_sitemap'] = 1
              }

              if(this.formLighthouse.disable_dynamic_sampling){
                data_post['disable_dynamic_sampling'] = 1
              }

              if(this.formLighthouse.dynamic_sampling){
                data_post['dynamic_sampling'] = this.formLighthouse.dynamic_sampling
              }

              if(this.formLighthouse.concurrency){
                data_post['concurrency'] = this.formLighthouse.concurrency
              }

              if(this.formLighthouse.ttl){
                data_post['ttl'] = this.formLighthouse.ttl
              }

              if(this.formLighthouse.job_timeout){
                data_post['job_timeout'] = this.formLighthouse.job_timeout
              }

              if(this.formLighthouse.job_cpu){
                data_post['job_cpu'] = this.formLighthouse.job_cpu
              }

              if(this.formLighthouse.job_memory){
                data_post['job_memory'] = this.formLighthouse.job_memory
              }
              
              if(this.formLighthouse.max_routes){
                data_post['max_routes'] = this.formLighthouse.max_routes
              }

              if(this.formLighthouse.status_job_region){
                data_post['job_region'] = this.formLighthouse.job_region
              }

              if(this.formLighthouse.status_auth){
                // data_post['username'] = this.formLighthouse.username
                // data_post['password'] = this.formLighthouse.password
                data_post['auth'] = JSON.stringify({
                  username: this.formLighthouse.username,
                  password: this.formLighthouse.password,
                })
              }

              if(this.formLighthouse.status_cookies){
                data_post['data_cookies'] = JSON.stringify(this.formLighthouse.cookies)
              }

              if(this.formLighthouse.status_extra_headers){
                let extra_headers = {}
                for(let e of this.formLighthouse.extra_headers){
                  extra_headers[e.name] = e.value
                }
                data_post['extra_headers'] = JSON.stringify(extra_headers)
              }

              if(this.formLighthouse.status_default_query_params){
                let default_query_params = {}
                for(let e of this.formLighthouse.default_query_params){
                  default_query_params[e.name] = e.value
                }
                data_post['default_query_params'] = JSON.stringify(default_query_params)
              }
            }
            
            if(this.$route.query && this.$route.query.enterprise_uuid){
              data_post['enterprise_uuid'] = this.$route.query.enterprise_uuid
            }  
            // console.log(data_post)
            // this.loading = false
            this.axios.post('/lighthouse/add', data_post)
            .then(res => {
              if (res.data.status === 'success') {
                let data_lighthouse = this.$store.state.app.data_lighthouse
                data_lighthouse['formLighthouse'] = null
                this.$store.dispatch('setDataLighthouse', data_lighthouse)
                this.$root.$snackbar(this.$t('item_saved'))
                this.$router.push({
                  name: 'lighthouse-history', 
                  query: this.$route.query
                })
              }
            })
            .catch(err => {
              if (err.response.data.status === 'error') {
                this.$root.$snackbar(err.response.data.msg)
              }
            })
            .finally(() => this.loading = false)
          }
        })
        
      },
      // CalTotalPrice() {
      //   let array_urls = []
      //   if(this.formLighthouse.tab == 'url' && this.formLighthouse.urls){
      //     const pattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[^\s]{2,})$/gi;
      //     let array = this.formLighthouse.urls.split('\n')

      //     // console.log(array)
      //     for(let urls of array){
      //       // console.log(urls)
      //       // console.log(pattern.test(urls))
      //       if(!urls){
      //         continue;
      //       }
      //       array_urls.push(urls)
      //       // if(pattern.test(urls)){
      //       //   array_urls.push(urls)
      //       // }
            
      //     }
      //   }
      //   //console.log(array_urls)
      //   this.formLighthouse.count_urls = array_urls.length
      //   this.formLighthouse.total_price = this.formLighthouse.price_lighthouse[this.formLighthouse.tab] * array_urls.length
      //   this.setDataLighthouse()
      // },
      addObject(name){
        this.formLighthouse[name].push({
          value: '',
          name: '',
        })
        this.$forceUpdate();
        //console.log(this.formLighthouse.cookies)
      },
      removeObject(name,index){
        this.formLighthouse[name].splice(index, 1)
      },
    }
  }
</script>
<style>
.custom-text-field{
  margin: 0 !important;
  width: 100%;
  max-width: 100% !important;
}
.border-radius-btn-toggle{
  border-radius: 5px!important;
}
.btn_object{
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>



