<template>
  <div class="auth-main viewContainer" >
    <v-container class="auth-container pa-4">
      <v-btn 
        :to="{ 
          name: (status_order_keyword ? 'tracking-keyword' : 'backlink-history'),
          query: $route.query
          }"
          small
          rounded
          depressed 
          class="no-caps mb-4"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        {{ $t('back') }}
      </v-btn>
      <!-- <v-btn @click="loading_main ? loading_main=false : loading_main=true">test</v-btn> -->
      <ValidationObserver ref="validateOrder" v-slot="{ invalid }">
        <v-form 
          ref="formOrder" 
        >
          <v-card rounded>
            <v-card-title class="pt-5 pl-5 pr-5 pb-2 ma-0">{{ $t('purchase_order') }}</v-card-title>
            <v-card-text class="pa-5 ma-0">

              <loading_input v-if="loading_main"/>

              <x-select
                v-if="!loading_main"
                v-model="formOrder.service_id"
                :items="item_service"
                :item-text="getItemText"
                item-value="uuid"
                :label="$t('service')"
                rules="required"
                filled
                @change="SelectService"
              >
              </x-select>

              <div 
              v-if="array_extra.length"
              class="mb-3"
              >
                  <h3 class="">{{ $t('service_extras') }}</h3>
                  <div 
                  v-for="item in array_extra"
                  :key="item.uuid"
                  >
                    <v-switch
                        v-model="formOrder.extras_id"
                        :value="item.uuid"
                        ref="extras_id"
                        id="extras_id"
                        :label="(item.status_serpclick ? $t(item.providers_extras_description) : item.providers_extras_description) + ' ['+($root.$refs.master.currency != 'USD' ? formatNumber(parseFloat(item.price)*$root.$refs.master.currency_rate) : parseFloat(item.price))+' '+( $root.$refs.master && $root.$refs.master.data_currency && $root.$refs.master.data_currency.symbol_hex ? hexToSymbols($root.$refs.master.data_currency.symbol_hex) : $root.$refs.master.currency )+']'"
                        inset
                        dense
                        :ripple="false"
                        name="extras_id"
                        :persistent-hint="true"
                        @change="CalTotalPrice"
                      />
                  </div>
              </div>

              <loading_input v-if="loading_main"/>

              <x-text-field
                v-if="!loading_main"
                v-model="formOrder.quantity"
                :label="$t('quantity')"
                :rules="'required|integer|between:'+(data_service && data_service.providers_service_min_qty ? data_service.providers_service_min_qty : 1)+',100000'"
                type="number"
                filled
                @keyup="CalTotalPrice"
              ></x-text-field>

              <v-card v-if="loading_main" flat tile class="content-tabs-card mt-10 mb-10" width="250">
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-card>

              <v-switch
                  v-if="!loading_main"
                  v-model="formOrder.status_day_distance"
                  class="mt-0"
                  ref="status_day_distance"
                  id="status_day_distance"
                  :label="$t('status_day_distance')"
                  inset
                  dense
                  :ripple="false"
                  name="status_day_distance"
                  :persistent-hint="true"
                  @change="setDataOrder"
              />

              <x-text-field
                v-if="formOrder.status_day_distance"
                v-model="formOrder.day_distance"
                :label="$t('day_distance')"
                :rules="'required|integer|between:1,90'"
                type="number"
                filled
                @keyup="setDataOrder"
              ></x-text-field>

              <div class="" v-if="!status_order_keyword && formOrder.links.length">
                <v-chip-group
                  active-class="primary--text"
                  column
                >
                  <v-chip
                    v-for="(link,index) in formOrder.links"
                    :key="index"
                    close
                    @click:close="removeLink(index)"
                  >
                    {{ link }}
                  </v-chip>
                </v-chip-group>
              </div>

              <loading_input v-if="loading_main"/>
              <x-text-field
                v-if="!status_order_keyword && !loading_main"
                v-model="formOrder.link"
                :label="$t('url')"
                placeholder="https://example.com/page-one"
                filled
                v-on:keyup.enter="enterLink"
              ></x-text-field>
              <!-- <v-combobox
                v-if="!status_order_keyword"
                v-model="formOrder.link"
                chips
                clearable
                multiple
                filled
                :label="$t('url')"
                placeholder="https://example.com/page-one"
                append-icon=""
                :class="label_link"
              >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                        small
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeLink(item)"
                        >
                        {{ item }}
                      </v-chip>
                  </template>
              </v-combobox> -->
              <div class="" v-if="!status_order_keyword && formOrder.keywords.length">
                <v-chip-group
                  active-class="primary--text"
                  column
                >
                  <v-chip
                    v-for="(keyword,index) in formOrder.keywords"
                    :key="index"
                    close
                    @click:close="removeKeyword(index)"
                  >
                    {{ keyword }}
                  </v-chip>
                </v-chip-group>
              </div>

              <loading_input v-if="loading_main"/>
              <x-text-field
                v-if="!status_order_keyword && !loading_main"
                v-model="formOrder.keyword"
                :label="$t('keyword')"
                :placeholder="$t('keyword')"
                filled
                v-on:keyup.enter="enterKeyword"
              ></x-text-field>

              <!-- <v-combobox
                v-if="!status_order_keyword"
                v-model="formOrder.keyword"
                chips
                clearable
                multiple
                filled
                :rules="[requiredLink]"
                :label="$t('keyword')"
                :placeholder="$t('keyword')"
                append-icon=""
              >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                        small
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeKeyword(item)"
                        >
                        {{ item }}
                      </v-chip>
                  </template>
              </v-combobox> -->
              <div
                v-if="status_order_keyword"
              > 
                <div v-if="select_all">

                  <v-card 
                    class="view_div mb-3 pa-5"
                    v-for="item in array_keyword"
                    :key="item.uuid"
                    outlined
                  > 
                    <div>
                      <div class="text-body-1">{{$t('url')}}</div>
                      <div class="text-h6">{{ decodeURIComponent(item.domain) }}</div>
                    </div>
                    <div >
                      <div class="text-body-1">{{$t('keyword')}}</div>
                      <div class="text-h6">{{ item.keyword }}</div>
                    </div>
                  </v-card>
                </div>
                <div v-else>
                  
                  <div  class="view_div d-flex justify-space-between">
                    <div class="text-body-1 mt-auto mb-auto">{{$t('url')}}</div>
                    <div class="text-h6 mt-auto mb-auto">{{ decodeURIComponent(domain) }}</div>
                  </div>
                  <div  class="view_div d-flex justify-space-between">
                    <div class="text-body-1 mt-auto mb-auto">{{$t('keyword')}}</div>
                    <div class="text-h6 mt-auto mb-auto">{{ keyword }}</div>
                  </div>
                </div>

                <div v-if="select_all" class="view_div d-flex justify-space-between">
                  <div class="text-body-1 mt-auto mb-auto">{{$t('quantity_keyword')}}</div>
                  <div class="text-h6 mt-auto mb-auto">{{ array_keyword.length }}</div>
                </div>
              </div>

              <loading_input v-if="loading_main"/>

              <div v-if="!loading_main" class="view_div d-flex justify-space-between">
                <div class="text-body-1 mt-auto mb-auto">{{$t('amount')}}</div>
                <div class="text-h6">
                  <amount_currency :amount="parseFloat(price)" top></amount_currency>
                </div>
              </div>
              
              <loading_input v-if="loading_main"/>

              <div v-if="!loading_main" class="view_div d-flex justify-space-between">
                <div class="text-body-1 mt-auto mb-auto">{{$t('quantity')}}</div>
                <div class="text-h6">{{ formOrder.quantity ? parseInt(formOrder.quantity) : 0 }}</div>
              </div>

              <loading_input v-if="loading_main"/>

              <div v-if="!loading_main" class="view_div d-flex justify-space-between">
                <div class="text-body-1 mt-auto mb-auto">{{$t('total_price')}}</div>
                <div class="text-h6 mt-auto mb-auto">
                  <amount_currency :amount="parseFloat(total_price)" top></amount_currency>
                </div>
              </div>

              <!-- <div  class="view_div d-flex justify-space-between">
                <div class="text-body-1">{{$t('day_distance')}}</div>
                <div class="text-h6">{{ formOrder.day_distance ? parseInt(formOrder.day_distance) : 0 }}</div>
              </div> -->
              
            </v-card-text>
            <v-card-actions class="pl-5 pr-5 pb-5 pt-0 ma-0 ">
              <v-spacer></v-spacer>
              <v-skeleton-loader
                v-if="loading_main" 
                class="mx-auto"
                type="button"
              ></v-skeleton-loader>
              <v-btn 
                v-if="!loading_main"  
                @click="SubmitOrder"
                color="primary" 
                depressed 
                class="no-caps"
                :loading="loading" :disabled="loading || invalid"
                >{{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-container>
  </div>
</template>
<script>
  
  export default {
    data: () => ({
      formOrder: {
        extras_id: [],
        status_day_distance: false,
        links: [],
        keywords: [],
      },
      loading: false,
      dialog: false,
      loading_main: true,
      resolve: null,
      reject: null,
      status_order_keyword: false,
      select_all: false,
      keyword_uuid: null,
      keyword: null,
      domain: null,
      array_keyword: [],
      item_service: [],
      data_service: {},
      item_extras: [],
      array_extra: [],
      price: 0,
      total_price: 0,
      lang_service: {},
      label_link: ''
    }),
    created () {
      // Set locale
      //console.log(this.country)
      this.locale = (this.$auth.check()) ? this.$auth.user().locale: Intl.DateTimeFormat().resolvedOptions().locale || this.$i18n.locale
      this.moment.locale(this.locale.substr(0,2))

      if(!this.$auth.user().page_order){
        this.$router.push({
          name: 'settings.profile',
        })
      }

      if(this.$route.params && (this.$route.params.uuid || this.$route.params.select_all)){
        //console.log(this.$route.params)
        this.status_order_keyword = true
        if(this.$route.params.select_all){
          this.select_all = true
          this.array_keyword = this.$route.params.array_keyword
          //console.log(this.array_keyword)
        }
        else{
          this.select_all = false
          this.keyword_uuid = this.$route.params.uuid
          this.keyword = this.$route.params.keyword
          this.domain = this.$route.params.domain
        }
        
        
      }
      
      //console.log(this.$store.state.app.data_order)
      if(this.$store.state && this.$store.state && this.$store.state.app.data_order && this.$store.state.app.data_order.lang_service){
        this.lang_service = this.$store.state.app.data_order.lang_service
        this.open()
      }
      else{
        this.axios.get('/backlink/get-lang-service')
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            this.lang_service = res.data.lang_service
            let data_order = this.$store.state.app.data_order
            data_order['lang_service'] = this.lang_service
            this.$store.dispatch('setDataOrder', data_order)
            this.open()
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
      }
      
    },
    methods: {
      formatNumber (value) {
        const num = Number(value);
        if (num && num !== 0 && Math.abs(num) < 0.01) {
            // ระบุตำแหน่งของตัวเลขที่สำคัญแรกโดยตรวจสอบจาก logarithm
            const significantDigits = Math.ceil(-Math.log10(Math.abs(num)));
            // ถ้าค่าที่ได้มากกว่า 2 ให้เรากำหนดความแม่นยำตามค่านั้น
            return num.toFixed(significantDigits > 2 ? significantDigits : 2);
        } else {
            // ค่ามากกว่าหรือเท่ากับ 0.01 ใช้ toFixed 2
            return new Intl.NumberFormat('th').format(num)
            //return num ? num.toFixed(2) : 0;
        }
        // let formatted = new Intl.NumberFormat('en-US', {
        //     minimumFractionDigits: 2,
        //     maximumFractionDigits: 20, // เพิ่มจำนวนทศนิยมสูงสุดที่จะแสดง
        //     useGrouping: false
        // }).format(number);

        // // ตรวจสอบว่ามีศูนย์ทศนิยมติดต่อกันมากกว่า 2 หลักหรือไม่
        // if (/0{2,}$/.test(formatted)) {
        //     // คืนค่าตัวเลขโดยตัดทศนิยมที่เป็นศูนย์ออกไป
        //     return parseFloat(formatted).toString();
        // }
        // return formatted;
        //return new Intl.NumberFormat('th').format(number)
      },
      hexToSymbols(hexString) {
        // แยกสตริงออกเป็นส่วนๆ โดยใช้เครื่องหมายเซมิโคลอน (;)
        const hexParts = hexString.split(';');
        
        // แปลงแต่ละส่วนจากเลขฐานสิบหกเป็นตัวอักษร และรวมกันเป็นสตริงเดียว
        const symbols = hexParts.map(part => String.fromCharCode(parseInt(part, 16))).join('');
        
        return symbols;
      },
      setDataOrder(){
        let data_order = this.$store.state.app.data_order
        data_order['formOrder'] = this.formOrder
        this.$store.dispatch('setDataOrder', data_order)
      },
      SelectService(){
        //console.log(this.formOrder.service_id)
        this.setArrayExtras()
        
        this.formOrder = {
          extras_id: [],
          status_day_distance: false,
          links: [],
          keywords: [],
        }

        this.CalTotalPrice()
      },
      setArrayExtras(){
        this.data_service = this.item_service.find(e => e.uuid == this.formOrder.service_id)
        // console.log(this.data_service)
        // console.log(this.item_extras)
        if(this.data_service){
          if(this.data_service.status_serpclick){
            this.array_extra = this.item_extras.filter(e => e.status_serpclick == 1 && e.serpclick_service_uuid == this.data_service.uuid)
          } 
          else{
            let array_extra_id = this.data_service.providers_service_extras.split(',')
            //console.log(array_extra_id)
            let array_extra = []
            for(let id of array_extra_id){
              let extra = this.item_extras.find(e => e.providers_extras_id == id)
              if(extra){
                array_extra.push(extra)
              }
              
            }
            this.array_extra = array_extra
          }
        }
      },
      CalTotalPrice() {
        //console.log(this.formOrder)  
        if(this.formOrder && this.formOrder.service_id && this.formOrder.quantity){
          let total_price = parseFloat(this.data_service.price)
          if(this.formOrder.extras_id.length){
            for(let uuid of this.formOrder.extras_id){
              let extra = this.array_extra.find(e => e.uuid == uuid)
              if(extra){
                //total_price += parseFloat(extra.price)
                total_price = this.addPrecise(total_price, parseFloat(extra.price))
              }
            }
          }

          if(this.select_all){
            this.price = this.multiplyPrecise(total_price,parseInt(this.formOrder.quantity))
            this.total_price = this.multiplyPrecise(this.price ,this.array_keyword.length)
          }
          else{
            this.price = total_price
            //this.total_price = (this.price * parseInt(this.formOrder.quantity))
            this.total_price = this.multiplyPrecise(this.price, parseInt(this.formOrder.quantity))
          }
          
        }
        else{
          this.total_price = 0
          this.price = 0
        }
        this.setDataOrder()
      },
      addPrecise(x, y) {
        let factor = Math.pow(10, 10); // ตั้งค่าความแม่นยำที่ต้องการ
        return (Math.round(x * factor) + Math.round(y * factor)) / factor;
      },
      multiplyPrecise(x, y) {
        let factor = Math.pow(10, 10); // กำหนดความแม่นยำที่ต้องการ
        return (Math.round(x * factor) * Math.round(y * factor)) / (factor * factor);
      },
      requiredLink(value) {
        if (value instanceof Array && value.length == 0) {
          return this.$t('invalid_value');
        }
        return !!value || this.$t('invalid_value');
      },
      requiredWebsite(value) {
        //console.log(value)
        if (value instanceof Array && value.length == 0) {
          return this.$t('invalid_website');
        }
        if(value.length > 0){
          //var re = /^(http|https):\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,7}$/; 
          var re = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig; 
          for(let link of value){    
            // console.log(link)
            // console.log(re.test(link))
            if (!re.test(link)) { 
              //console.log(link)
              this.formOrder.link = this.formOrder.link.filter(e => e !== link);
              return this.$t('invalid_website');
            }
          }
        }
        //console.log(value)
        return !!value || this.$t('invalid_website');
      },
      open() { 

        if(this.$store.state && this.$store.state && this.$store.state.app.data_order && this.$store.state.app.data_order.service){
          this.setData(this.$store.state.app.data_order.service,this.$store.state.app.data_order.extras)
          this.loading_main = false
        }
        else{
          this.loading_main = true
          this.axios.get('/backlink/get-service-extra')
          .then(res => {
            if (res.data.status === 'success') {
              let service = JSON.parse(JSON.stringify(res.data.service))
              let new_service = []
              for(let e of service){
                let array = JSON.parse(JSON.stringify(e))
                if(!e.status_serpclick){
                  let description = this.LangServce(e.providers_service_id,e.providers_id,'service')
                  array.providers_service_description = description
                }
                
                new_service.push(array)
              }

              let extras = JSON.parse(JSON.stringify(res.data.extras))
              let new_extras = []
              for(let e of extras){
                let array = JSON.parse(JSON.stringify(e))
                if(!e.status_serpclick){
                  let description = this.LangServce(e.providers_extras_id,e.providers_id,'extras')
                  array.providers_extras_description = description
                }
                new_extras.push(array)
              }
              let data_order = this.$store.state.app.data_order
              data_order['service'] = new_service
              data_order['extras'] = new_extras
              this.$store.dispatch('setDataOrder', data_order)
              this.setData(new_service,new_extras)
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading_main = false)
        }

        
      },
      getItemText(item){  

        let currency = ''
        if(this.$root.$refs.master.currency != 'USD'){
          currency = this.formatNumber(parseFloat(item.price)*this.$root.$refs.master.currency_rate)
        }
        else{
          currency = parseFloat(item.price)
        }
        let symbol = ''
        if(this.$root.$refs.master && this.$root.$refs.master.data_currency && this.$root.$refs.master.data_currency.symbol_hex){
          symbol = this.hexToSymbols(this.$root.$refs.master.data_currency.symbol_hex)
        }
        else{
          symbol = this.$root.$refs.master.currency
        }
        return item.providers_service_description +' ['+ currency + ' '+symbol+']'
      },
      setData(item_service,item_extras) {
        if(this.$refs.validateOrder){
          this.$refs.validateOrder.reset();
        }
        this.item_service = item_service
        this.item_extras = item_extras
        
        this.data_service = {}
        this.array_extra = []
        this.total_price = 0
        this.price = 0

        if(this.$store.state && this.$store.state && this.$store.state.app.data_order && this.$store.state.app.data_order.formOrder){
          this.formOrder = this.$store.state.app.data_order.formOrder
          this.setArrayExtras()
          this.CalTotalPrice()
        }
        else{
          this.formOrder = {
            extras_id: [],
            status_day_distance: false,
            links: [],
            keywords: [],
          }
        }
      },
      LangServce(service_id,providers_id,type){
        //console.log(this.lang_service)
        //console.log(type)
        let language = this.$store.state && this.$store.state.app && this.$store.state.app.language ? this.$store.state.app.language.toLowerCase() : 'th-en'

        if(this.lang_service && this.lang_service[language]){
          let service = this.lang_service[language].find(e => e.providers_id == providers_id && e.type == type && e.providers_service_id == service_id)
          //console.log(service)
          if(service){
            return service.providers_service_description_translation
          }
          else{
            return ''
          }
        }
        else{
          return ''
        }
      },
      // removeKeyword(item){
      //   // console.log(item)
      //   // console.log(this.formOrder.keyword)
      //   this.formOrder.keyword = this.formOrder.keyword.filter(e => e !== item);
      // },
      enterLink(){
        let link = this.formOrder.link
        this.formOrder.links.push(link)
        this.formOrder.link = ''
        this.setDataOrder()
        // var re = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig; 
        // if (re.test(link)) { 
        //   this.links.push(link)
        //   this.formOrder.link = ''
        // }
        // else{
        //   this.$root.$snackbar(this.$t('invalid_website'))
        // }
       
      },
      removeLink(index) {
        this.formOrder.links.splice(index, 1)
      },
      enterKeyword(){
        let keyword = this.formOrder.keyword
        this.formOrder.keywords.push(keyword)
        this.formOrder.keyword = ''
        this.setDataOrder()
      },
      removeKeyword(index) {
        this.formOrder.keywords.splice(index, 1)
      },
      // removeLink(item){
      //   // console.log(item)
      //   // console.log(this.formOrder.link)
      //   this.formOrder.link = this.formOrder.link.filter(e => e !== item);
      // },
      SubmitOrder() {
        //this.label_link = ''
        let post = {}
        
        if(this.data_service.providers_service_contextual == '1' && !this.formOrder.extras_id.length){
          this.$root.$snackbar(this.$t('invalid_service_extras'))
            return false
        }
        // console.log(this.data_service.status_serpclick)
        // console.log(this.formOrder.status_day_distance)
        // console.log(this.formOrder.quantity)
        // console.log(this.formOrder.day_distance)
        if(this.data_service.status_serpclick && this.formOrder.status_day_distance && parseInt(this.formOrder.quantity) < parseInt(this.formOrder.day_distance)){
          this.$root.$snackbar(this.$t('alert_day_distance'))
          return false
        }
        
        if(!this.status_order_keyword){
          if(!this.formOrder.keywords.length){
            this.$root.$snackbar(this.$t('invalid_value'))
            return false
          }
          if(!this.formOrder.links.length){
            this.$root.$snackbar(this.$t('invalid_website'))
            //this.label_link = 'error--text'
            return false
          }
          // else{
          //   for(let link of this.formOrder.link){    
          //     // console.log(link)
          //     // console.log(re.test(link))
          //     var re = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig; 
          //     if (!re.test(link)) { 
          //       this.$root.$snackbar(this.$t('invalid_website'))
          //       //this.label_link = 'error--text'
          //       return false
          //     }
          //   }
          // }  
          post = {
            status_keyword_order: 0,
            multiple_order: 0,
            service_id: this.formOrder.service_id,
            quantity: this.formOrder.quantity,
            status_day_distance: (this.formOrder.status_day_distance ? 1 : 0),
            day_distance: this.formOrder.day_distance,
            extras_id: JSON.stringify(this.formOrder.extras_id),
            keyword: JSON.stringify(this.formOrder.keywords),
            link: JSON.stringify(this.formOrder.links)
          }
        }
        else{
          post = {
            status_keyword_order: 1,
            service_id: this.formOrder.service_id,
            quantity: this.formOrder.quantity,
            status_day_distance: (this.formOrder.status_day_distance ? 1 : 0),
            day_distance: this.formOrder.day_distance,
            extras_id: JSON.stringify(this.formOrder.extras_id),
          } 

          if(this.select_all){
            post.multiple_order = 1
            post.array_keyword = JSON.stringify(this.array_keyword)
          }
          else{
            post.multiple_order = 0
            post.keyword_uuid = this.keyword_uuid
            post.keyword = this.keyword
            post.link = this.domain
          }
        }

        if(this.$route.query && this.$route.query.enterprise_uuid){
          post['enterprise_uuid'] = this.$route.query.enterprise_uuid
        }

        this.$root.$confirm(this.$t('confirm'), this.$t('confirm_create_order'),{
          color: 'primary',
          width: 360,
          zIndex: 200
        },false)
        .then((confirm) => {
          if (confirm.confirm) {
            this.loading = true
            this.axios.post('/backlink/add', post)
            .then(res => {
              if (res.data.status === 'success') {
                this.$root.$refs.master.getCredit()
                this.$root.$snackbar(this.$t('purchase_order_success'))

                let data_order = this.$store.state.app.data_order
                data_order['formOrder'] = null
                this.$store.dispatch('setDataOrder', data_order)

                //this.$root.$refs.dataTableReload()
                this.$router.push({
                  name: 'backlink-history', 
                  query: this.$route.query, 
                })
              }
            })
            .catch(err => {
              if (err.response.data.status === 'error') {
                if(err.response.data.type === 'credit_error'){
                  if(this.$route.query && this.$route.query.enterprise_uuid){
                    this.$root.$snackbar(err.response.data.msg)
                  }
                  else{
                    this.$root.$snackbar(err.response.data.msg)
                    this.$router.push({
                      name: 'billing-payment', 
                      query: this.$route.query, 
                      params: { 
                        credit: err.response.data.credit
                      }
                    })
                  }
                }
                else{
                  this.$root.$snackbar(err.response.data.msg)
                }
              }
            })
            .finally(() => this.loading = false)
          }
        })
      },
    }
  }
</script>
<style>
.v-chip {
  max-width: 100%; /* ปรับขนาดนี้ให้เหมาะสมกับตัวเลขที่คุณต้องการ */
  overflow: hidden; /* ตัดข้อความที่เกินออก */
  
}

/* ตรวจสอบให้แน่ใจว่าปุ่มลบมีพื้นที่เพียงพอ */
.v-chip .v-chip__close {
  position: absolute;
  top: 50%;
  right: 7px; /* หรือปรับค่านี้ให้เหมาะสมกับสไตล์ของคุณ */
  transform: translateY(-50%);
}

/* ใช้สไตล์ ellipsis เมื่อข้อความเกิน */
.v-chip__content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}
.btn_loading_last .v-skeleton-loader__button{
  width: 120px;
}
.custom-text-field{
  margin: 0 !important;
  width: 100%;
  max-width: 100% !important;
}
</style>