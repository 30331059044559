<template>
  <div class="viewContainer" >
    <v-container
      :class="['pa-4', 'pa-sm-8', $store.state.app.classExpandContainer]"
    >
      <v-card class="content-tabs-wrapper pa-4 pa-sm-8" elevation="0">
        <panel_create
        :title="$t('payment')"
        :msg="$t('msg_title_create_payment')"
        :create_item="$t('top_up')"
        :redirect="'billing-payment'"
        >
        </panel_create>
        <data-table
          model="App\Models\Accounting"
        >
          <template v-slot:empty-head>
            <v-icon size="72">mdi-folder-multiple</v-icon>
          </template>
          <template v-slot:empty-text>
          </template>
        </data-table>
      </v-card>
    </v-container>
  </div>
</template>


<script>
// import { copyStringToClipboard } from '@/utils/helpers';
// import country from '@/json/country.json'

export default {
  data: () => ({
    locale: 'en',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    loading: false,
  }),
  created () {
    // Set locale
    //console.log(this.country)
    this.locale = (this.$auth.check()) ? this.$auth.user().locale: Intl.DateTimeFormat().resolvedOptions().locale || this.$i18n.locale
    this.moment.locale(this.locale.substr(0,2))

    if(this.$auth.user().status_enterprise || !this.$auth.user().page_payment){
      this.$router.push({
        name: 'settings.profile',
      })
    }

    let uuid = this.$auth.user().uuid
    var channel = this.$pusher.subscribe(uuid);
    //console.log(channel)
    channel.bind('webhook_qr_omise', (res_pusher) => {
      //console.log(res_pusher)
      if(res_pusher && res_pusher.status == 'success'){
        //console.log(res_pusher.amount)
        //console.log(this.formatNumber(res_pusher.amount))
        this.$root.$refs.master.getCredit()
        this.$root.$refs.dataTableReload()
        //console.log(res_pusher.amount) 
        this.$root.$snackbar(this.$t('received_credit',{amount: this.formatNumber(res_pusher.amount)}))
        this.$root.$close_qrcode_promptpay()
      }
    });
    channel.bind('test_webhook', (res_pusher) => {
      console.log(res_pusher)
    }); 
  },
  methods: {
    //copyStringToClipboard,
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
  },
  computed: {
    qrWidth: function () {
      let w = parseInt(window.innerWidth) - 72
      return (w > 443) ? 443 : w
    }
  }
}
</script>
<style>
.background-color-black{
  background-color: black;
}
.v-dialog--fullscreen{
  overflow: hidden;
}
</style>